import { Button, Col, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

import { FiPhone } from 'react-icons/fi';
import Layout from '../component/layout/layout';
import SEO from '../component/seo';
import YoutubeVideo from '../component/video/YoutubeVideo';

const ThanksPage = () => {
  const [notIE, setNotIE] = useState(false);

  if (typeof window !== 'undefined' && notIE) {
    window.g3cm =
      window.g3cm ||
      function () {
        // eslint-disable-next-line no-extra-semi
        (window.g3cm.q = window.g3cm.q || []).push(arguments);
      };
    window.g3cm('loadjquery', 'true');
    const phone = document.cookie.match(/leadphone=(\d+);?/);
    if (phone) {
      window.g3cm('phonenumber', phone[1]);
      window.g3cm('send', 'init');
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.onbeforeunload = null;

      if (/Trident\/|MSIE/.test(window.navigator.userAgent)) {
        return;
      } else {
        setNotIE(true);
      }
    }
  }, []);

  return (
    <>
      <SEO title='Thanks' />

      <Layout stickyFooter={false}>
        {notIE && (
          <script
            src='https://api.connectstreams.com/js/connectme-v3.min.js'
            async='async'
          ></script>
        )}
        <Row
          className='g-0 sidebar-layout thanks-layout'
          style={{ backgroundColor: '#E5E5E5' }}
        >
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={3}
            className='d-none d-md-block thanks-left-sec'
          >
            <div className='sidebar-section p-0'></div>
          </Col>
          <Col xs={12} sm={12} md={8} lg={9} className='thanks-section'>
            <div className='thanks-content'>
              <h3>
                Wow! We found over <span>250 plans</span> in your area
              </h3>
              <div
                className='thanks-subtitle g3cm_holder'
                data-module='1769'
                data-showoninit='true'
                style={{ display: 'none' }}
              >
                <p>
                  Our Medicare Experts are working hard to find the best plans
                  for you. Thank You for your inquiry to Medicare Compared!
                </p>
              </div>
              <div className='thanks-subtitle'>
                <p>Give us a call so we can email or mail the plans to you!</p>
                <a
                  href='tel:+18883799437'
                  rel='noopener noreferrer'
                  id='callInNum'
                >
                  <Button variant='btn btn-primary-custom d-block xs-mb10'>
                    <FiPhone color='white' style={{ marginRight: 20 }} />
                    Call <span>(888) 379-9437</span>
                  </Button>
                </a>
              </div>
              <hr style={{ width: '100%' }} />

              <div>
                <div className='thanks-video-text'>
                  <p>
                    If you have an extra couple of minutes, please watch the
                    video
                  </p>
                  <div className='thanks-video'>
                    <YoutubeVideo />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Layout>
    </>
  );
};
export default ThanksPage;
